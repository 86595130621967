var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('TTableAdvance',{attrs:{"items":_vm.angencies,"fields":_vm.fields,"store":"warehouse.agencies","resource":"/management/agency","creatable":"","deletable":""},on:{"click-clear-filter":_vm.clearFilter,"click-create":function($event){_vm.showModalCreate = true}},scopedSlots:_vm._u([{key:"append-actions",fn:function(ref){
var item = ref.item;
return [_c('CCol',{staticClass:"px-1 text-center",attrs:{"col":"12"}},[_c('TButtonEdit',{on:{"click":function($event){return _vm.editItem(item)}}})],1)]}},{key:"name",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessage',{attrs:{"content":item.name,"noTranslate":""}})],1)]}},{key:"tel",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessage',{attrs:{"content":item.tel,"noTranslate":""}})],1)]}},{key:"address",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessage',{attrs:{"content":item.address,"noTranslate":""}})],1)]}},{key:"note",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessage',{attrs:{"content":item.note,"noTranslate":""}})],1)]}},{key:"user",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.user)?_c('TMessage',{attrs:{"content":item.user.name,"noTranslate":""}}):_c('TMessageNotFound')],1)]}},{key:"coordinate",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.latitude)?_c('TMessage',{attrs:{"content":item.latitude,"noTranslate":""},scopedSlots:_vm._u([{key:"suffix",fn:function(){return [_vm._v(" , "+_vm._s(item.longitude)+" ")]},proxy:true}],null,true)}):_c('TMessageNotFound')],1)]}},{key:"created_at",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessageDateTime',{attrs:{"content":item.created_at,"small":""}})],1)]}},{key:"id-filter",fn:function(){return [_c('TInputText',{attrs:{"placeholder":"Agency Id","value":_vm.filter.id},on:{"update:value":[function($event){return _vm.$set(_vm.filter, "id", $event)},_vm.filterChange]}})]},proxy:true},{key:"name-filter",fn:function(){return [_c('TInputText',{attrs:{"placeholder":"Agency name","value":_vm.filter.name},on:{"update:value":[function($event){return _vm.$set(_vm.filter, "name", $event)},_vm.filterChange]}})]},proxy:true}])}),_c('AgencyModal',{attrs:{"show":_vm.showModalCreate},on:{"update:show":function($event){_vm.showModalCreate=$event}}}),_c('AgencyModal',{attrs:{"show":_vm.showModalEdit,"agency":_vm.detail},on:{"update:show":function($event){_vm.showModalEdit=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }